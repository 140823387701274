window.addEventListener("DOMContentLoaded", () => {

    if ("IntersectionObserver" in window) {



        if (window.matchMedia("(prefers-reduced-motion: reduce)").matches) {
            return;
        }

        let observerOptions = {
            rootMargin: '150px 150px 150px 150px',
            threshold: [1, 1, 1, 1]
        };

        //const $items = $(".ldc-custom, main h2:not(.visuallyhidden):not(.sr-only)");
        const $items = $(".ldc-custom");
        $items.each(function (n, t) {
            if (!$(this).text().trim().length)
                return;
            var e = 1
                , o = $(t).find("a").length > 0 ? $(t).find("a")[0] : t
                , s = $(o).text().trim()
                , r = s.split(" ")
                , i = ""
                , u = Math.floor(Math.random() * 3) + 1
                , f = !0
                , h = 0;
            if (r.length > 1) {
                for (var x = 0; x < r.length; x++)
                    f &&
                        (h++,
                            i += "<span style='z-index:" + e + "'",
                            (u > 1 || r[x].length < 4) &&
                            (i += " class='",
                                r[x].length < 4 && (i += "fiftyPercent"),
                                u > 1 && (i += " bg" + u),
                                i += "'"),
                            i += h > 1 ? "> " : ">"),
                        i += r[x],
                        f = !0,
                        x < r.length - 1 &&
                        (r[x].length < 4 && r[x + 1].length < 4 && (f = !1),
                            i += " "),
                        f &&
                        (i += "<\/span>",
                            u += 1,
                            u > 3 && (u = 1),
                            e = e == 1 ? 2 : 1);
            } else {
                i = "<span>" + s + "<\/span>";
            }

            i !== "" && $(o).html(i);
        });

        const customHeadersObeserver = new IntersectionObserver((entries, textObserver) =>  {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    const currentItem = entry.target;
                    currentItem.classList.add("visible");

                    $(currentItem).find("span").each(function (n, t) {
                        setTimeout(function () {
                            $(t).addClass("animateIn");
                        }, n * 150);
                    });
                    textObserver.unobserve(currentItem);
                }
            });
        }, observerOptions);

        $items.each((n, t) => {
            customHeadersObeserver.observe(t);
        });


    } else {
        console.log("Sorry, but your browser is ancient and does not support IntersectionObserver");

        const headlines = document.querySelectorAll('.ldc-custom');

        headlines.forEach(headline => headline.classList.add('visible'));
    }


});